
$(function(){
	const form = $('#loginForm');
	new Foundation.Abide(form);
	form.on('formvalid.zf.abide', function () {
		let button = form.find('#login-button');
		let spinner = button.find('.spinner');
		$('#errors').fadeOut(200).html('');
		button.prop('disabled', true).addClass('disabled');

		authorise().done(function(data) {
			if (data.error == true) {
				$('#errors').html(data.errorMsg).fadeIn(200);
				spinner.addClass('hide');
				return;
			} else {
				googappuid = data.googappuid || 1;
				document.cookie = 'GOOGAPPUID=' + googappuid;
			}
			authorise
			location.reload();
		}).fail(function (xhr, textStatus, errorThrown) {
			$('#errors').html('Error: ' + errorThrown).fadeIn(200);
		}).always(function() {
			button.prop('disabled', false).removeClass('disabled');
			spinner.addClass('hide').hide();
		});
	}).on('submit', function(ev) {
		ev.preventDefault();
	});
})

function authorise() {
	return	$.ajax({
		url: '/login/authorise',
		type: 'POST',
		dataType: 'json',
		data: $('#loginForm').serialize(),
		beforeSend: function() {
			$('#login-button .spinner').removeClass('hide');
		}
	});
}